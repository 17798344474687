import {
  DefaultType,
  MapperOperatorType,
  MapperValueType,
  PaymentIntervalType,
  ProductPropertyType,
} from './enum-mapping.type';

export interface Mapper {
  value: MapperValue;
  conditions: MapperCondition[];
}

export interface MapperValue {
  type: MapperValueType;
  value?: string;
  property?: ProductPropertyType;
  dataFieldContentId?: string;
  contentId?: string;
  paymentInterval?: PaymentIntervalType;
}

export interface MapperCondition {
  keyValue: MapperValue;
  operator: MapperOperatorType;
  matchingValue: MapperValue;
}

export function createMapper(): Mapper {
  return {
    value: createValue(),
    conditions: [],
  };
}

export function createCondition(): MapperCondition {
  return {
    keyValue: createValue(),
    operator: DefaultType.OPERATOR,
    matchingValue: createValue(),
  };
}

export function hasPaymentInterval(propertyType: ProductPropertyType | undefined): boolean {
  switch (propertyType) {
    case ProductPropertyType.PAYMENT_INTERVAL:
    case ProductPropertyType.PRICE:
    case ProductPropertyType.VAT:
      return true;
    case ProductPropertyType.DESCRIPTION:
    case ProductPropertyType.NAME:
    case ProductPropertyType.QUANTITY:
    default:
      return false;
  }
}

function createValue(): MapperValue {
  return {
    type: DefaultType.VALUE,
  };
}
