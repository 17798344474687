import { AuthenticationAwsService } from '@service/authentication.service';
import { Environment } from './environment.type';

export const environment: Environment = {
  atruvia: null,
  aws: {
    cognitoUserPoolId: 'eu-central-1_1R64tmJHl',
    cognitoClientId: '2olr74vgj4ulnfhqs6pt936me0',
  },
  service: {
    authentication: AuthenticationAwsService,
  },
  caUrl: 'ca/api/v1',
  gshUrl: 'gsh/api/v1',
  local: false,
};

export function getAuthenticationService(): AuthenticationAwsService {
  return AuthenticationAwsService.prototype;
}
