import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TreeNodeUtil } from '@app/util/tree-node.util';
import { TreeContextService } from '@service/tree-context.service';
import { DefaultData } from '@type/internal/default.data';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { ExternalNodeType, InternalNodeType } from '@type/shared/enum-mapping.type';

@Component({
  selector: 'app-object-node',
  templateUrl: './object-node.component.html',
  styleUrls: ['./object-node.component.scss'],
})
export class ObjectNodeComponent {
  @ViewChild('duplicateNameError') public duplicateNameError?: TemplateRef<Element>;
  @Input() public selectedTreeNode?: TreeNode<InternalMappingData>;

  public newNodeName?: string;

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _selectTreeNodeService: TreeContextService
  ) {}

  public drop(event: CdkDragDrop<TreeNode<InternalMappingData>[]>) {
    if (event.previousContainer === event.container && event.container.data) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  public showAddPropertyButton(): boolean {
    return (
      this.selectedTreeNode!.data.type !== InternalNodeType.FIELD &&
      !(this.isAnonymousArrayType() && this.selectedTreeNode!.hasChildren())
    );
  }

  public disableAddProperty(): boolean {
    if (this.isAnonymousArrayType()) {
      return false;
    }
    return this.newNodeName === undefined || this.newNodeName.length === 0;
  }

  public createFieldNode(): void {
    this.createNode(ExternalNodeType.FIELD);
  }

  public createObjectNode(): void {
    this.createNode(ExternalNodeType.OBJECT);
  }

  public createArrayNode(): void {
    this.createNode(ExternalNodeType.ARRAY);
  }

  public createStaticArrayNode(): void {
    this.createNode(ExternalNodeType.STATIC_ARRAY);
  }

  public createAnonymousArrayNode(): void {
    this.createNode(ExternalNodeType.ANONYMOUS_ARRAY);
  }

  public createAnonymousStaticArrayNode(): void {
    this.createNode(ExternalNodeType.ANONYMOUS_STATIC_ARRAY);
  }

  public removeField(index: number): void {
    this.selectedTreeNode!.children.splice(index, 1);
  }

  public getVisibleChildren(): TreeNode<InternalMappingData>[] {
    if (this.isAnonymousArrayType()) {
      return this.selectedTreeNode!.hasChildren() ? [this.selectedTreeNode!.children[0]] : [];
    }
    return this.selectedTreeNode!.children;
  }

  public isAnonymousArrayType(): boolean {
    return (
      this.selectedTreeNode!.data.type === InternalNodeType.ANONYMOUS_ARRAY ||
      this.selectedTreeNode!.data.type === InternalNodeType.ANONYMOUS_STATIC_ARRAY
    );
  }

  private createNode(nodeType: ExternalNodeType): boolean {
    if (this.isAnonymousArrayType()) {
      this.newNodeName = DefaultData.ANONYMOUS_ARRAY_ELEMENT;
    }

    if (this.isDuplicateName()) {
      this._snackBar.openFromTemplate(this.duplicateNameError!);
      return false;
    }

    const data = new InternalMappingData({ type: nodeType });

    const staticArrayParent = TreeNodeUtil.getStaticArrayParent(this.selectedTreeNode!);
    if (staticArrayParent) {
      const length = staticArrayParent.data.mappingSourceLength!;
      data.mappingSourceLength = length;
      data.mappingSources = TreeNodeUtil.fillMappingSourceData(length, data.mappingSources ?? []);
    }

    const newTreeNode = new TreeNode<InternalMappingData>(this.newNodeName!, data);
    this.selectedTreeNode!.addChild(newTreeNode);

    this._selectTreeNodeService.setSelected(this.selectedTreeNode!);

    this.newNodeName = undefined;
    return true;
  }

  private isDuplicateName(): boolean {
    if (this.newNodeName && this.newNodeName.length > 0) {
      return this.selectedTreeNode!.children.find(child => child.name === this.newNodeName) === undefined
        ? false
        : true;
    }
    return true;
  }
}
