import { Inject, Injectable } from '@angular/core';
import { ServiceConstant } from '@app/constants.data';
import { ConsultationAppRepository } from '@app/repository/consultation-app.repository';
import { AbstractAuthenticationService, AuthenticationState } from '@service/authentication.service';
import {
  CompositionDTO,
  DataFieldGroupDTO,
  InstanceDTO,
  ProductDTO,
  ProductTypeDTO,
} from '@type/external/banking-guide.type';
import { DataFieldData, DataFieldGroupData, InstanceData, ProductData } from '@type/internal/internal-form.type';
import { BehaviorSubject, Observable, combineLatestWith, lastValueFrom } from 'rxjs';
import { GuidedServiceHubRepository } from '../repository/guided-service-hub.repository';

@Injectable({
  providedIn: 'root',
})
export class InstanceContextService {
  public readonly dataFieldGroupSubject: BehaviorSubject<DataFieldGroupData[]>;
  public readonly generalDataFieldsSubject: BehaviorSubject<DataFieldData[]>;
  public readonly productDataFieldSubject: BehaviorSubject<DataFieldData[]>;
  public readonly additionalProductsSubject: BehaviorSubject<ProductData[]>;
  public readonly instancesSubject: BehaviorSubject<InstanceData[]>;

  private _selectedInstanceSubject: BehaviorSubject<InstanceData | undefined>;
  private _refreshActive: boolean;

  constructor(
    private _gshClientService: GuidedServiceHubRepository,
    private _caClientService: ConsultationAppRepository,
    @Inject(ServiceConstant.authenticationService) private _authenticationService: AbstractAuthenticationService
  ) {
    this.dataFieldGroupSubject = new BehaviorSubject<DataFieldGroupData[]>([]);
    this.generalDataFieldsSubject = new BehaviorSubject<DataFieldData[]>([]);
    this.productDataFieldSubject = new BehaviorSubject<DataFieldData[]>([]);
    this.additionalProductsSubject = new BehaviorSubject<ProductData[]>([]);
    this.instancesSubject = new BehaviorSubject<InstanceData[]>([]);
    this._selectedInstanceSubject = new BehaviorSubject<InstanceData | undefined>(undefined);
    this._refreshActive = false;
    this._authenticationService.authorizationState.subscribe(state => this._onAuthenticationStateChange(state));
  }

  public canRefresh(): boolean {
    return !this._refreshActive;
  }

  public refreshInstances() {
    if (!this._refreshActive) {
      this._refreshActive = true;
      this._gshClientService.getInstances().subscribe({
        next: instances => this._getInstancesSuccess(instances),
        error: _ => this._getInstancesError(),
      });
    }
  }

  public setNextSelectedInstance(instance?: InstanceData) {
    this._authenticationService.instanceId = instance?.id;
    if (instance) {
      this._refreshDataInternal();
    } else {
      this.dataFieldGroupSubject.next([]);
      this.generalDataFieldsSubject.next([]);
      this.productDataFieldSubject.next([]);
      this.additionalProductsSubject.next([]);
    }
    this._selectedInstanceSubject.next(instance);
  }

  public getSelectedInstance(): InstanceData | undefined {
    return this._selectedInstanceSubject.getValue();
  }

  public getSelectedInstanceObservable(): Observable<InstanceData | undefined> {
    return this._selectedInstanceSubject.asObservable();
  }

  /* refresh data internal*/
  private _refreshDataInternal() {
    if (this._authenticationService.isAuthenticatedWithInstanceContext()) {
      lastValueFrom(this._caClientService.getDataFieldGroups()).then(df => this._refreshDataFields(df));

      const compositionsObservable: Observable<CompositionDTO[]> = this._caClientService.getCompositions();
      const productsObservable: Observable<ProductDTO[]> = this._caClientService.getProducts();
      compositionsObservable
        .pipe(combineLatestWith(productsObservable))
        .subscribe(([c, p]) => this._refreshAdditionalProducts(c, p));
    } else {
      console.error(`Not authorized `);
    }
  }

  private _refreshDataFields(dataFieldGroupDTOs: DataFieldGroupDTO[]): void {
    const dataFieldGroups: DataFieldGroupData[] = dataFieldGroupDTOs.map(dfg => new DataFieldGroupData(dfg));
    const generalDataFields: DataFieldData[] = dataFieldGroupDTOs
      .map(dfg => {
        return dfg.dataFields.flat().map(df => new DataFieldData(df, dfg));
      })
      .flat();

    const productDataFields: DataFieldData[] = dataFieldGroupDTOs
      .filter(dfg => dfg.productRefs)
      .map(dfg => {
        return dfg.dataFields.flat().map(df => new DataFieldData(df, dfg));
      })
      .flat();

    this.dataFieldGroupSubject.next(dataFieldGroups);
    this.generalDataFieldsSubject.next(generalDataFields);
    this.productDataFieldSubject.next(productDataFields);
  }

  private _refreshAdditionalProducts(compositions: CompositionDTO[], products: ProductDTO[]): void {
    const additionalProductIds: string[] = compositions
      .filter(c => c.productRefs)
      .map(c => c.productRefs)
      .flat()
      .map(ref => ref.productId);
    const additionalProductIdSet = new Set<string>();
    const additionalProductDatas: ProductData[] = [];
    products
      .filter(p => additionalProductIds.includes(p.id))
      .forEach(p => {
        if (!additionalProductIdSet.has(p.id)) {
          additionalProductIdSet.add(p.id);
          additionalProductDatas.push(new ProductData(p, ProductTypeDTO.ADDITIONAL));
        }
      });
    this.additionalProductsSubject.next(additionalProductDatas);
  }

  private _onAuthenticationStateChange(state: AuthenticationState): void {
    if (state === AuthenticationState.LOGGED_IN) {
      this.refreshInstances();
    }
  }

  /* Callback handler */
  private async _getInstancesSuccess(instances: InstanceDTO[]): Promise<void> {
    this.instancesSubject.next(instances);
    await this.setNextSelectedInstance(this._selectedInstanceSubject.getValue());
    this._refreshActive = false;
  }

  private _getInstancesError(): void {
    this.setNextSelectedInstance(undefined);
    this.instancesSubject.next([]);
    this._refreshActive = false;

    console.error('get instance fail');
  }
}
