<div>
  <kf-tile [interactive]="false" class="layout-box" *ngIf="selectedTreeNode as selected">
    <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="getVisibleChildren()">
      <div cdkDrag *ngFor="let field of selected.children; let fieldIndex = index" [cdkDragData]="field">
        <kf-tile>
          <div class="d-flex align-items-center">
            <mat-label class="flex-fill">{{ field.name }}</mat-label>
            <button
              mat-icon-button
              matSuffix
              disableRipple
              class="kf-icon-button-small kf-icon-button-neutral ml-auto"
              style="cursor: pointer"
              (click)="removeField(fieldIndex)"
            >
              <kf-icon name="ic_schliessen_16"></kf-icon>
            </button>
          </div>
        </kf-tile>
      </div>
    </div>
  </kf-tile>
  <button
    mat-button
    class="m-3"
    *ngIf="showAddPropertyButton()"
    [matMenuTriggerFor]="addFieldMenu"
    aria-label="Create new field"
    yPosition="above"
  >
    <kf-icon name="ic_pluszeichen_24"></kf-icon>
    <span>Add property</span>
  </button>

  <mat-menu #addFieldMenu>
    <div class="p-2" style="min-width: 10em">
      <mat-form-field *ngIf="!isAnonymousArrayType()" (click)="$event.stopPropagation()" style="border: none">
        <input matInput [(ngModel)]="newNodeName" placeholder="Name" (click)="$event.stopPropagation()" />
      </mat-form-field>
      <button mat-menu-item class="kfcolor-grey-600" (click)="createFieldNode()" [disabled]="disableAddProperty()">
        Field
      </button>
      <button mat-menu-item class="kfcolor-grey-600" (click)="createObjectNode()" [disabled]="disableAddProperty()">
        Object
      </button>
      <button mat-menu-item class="kfcolor-grey-600" (click)="createArrayNode()" [disabled]="disableAddProperty()">
        Array
      </button>
      <button
        mat-menu-item
        class="kfcolor-grey-600"
        (click)="createStaticArrayNode()"
        [disabled]="disableAddProperty()"
      >
        Static Array
      </button>
      <button
        mat-menu-item
        class="kfcolor-grey-600"
        (click)="createAnonymousArrayNode()"
        [disabled]="disableAddProperty()"
      >
        Anonymous Array
      </button>
      <button
        mat-menu-item
        class="kfcolor-grey-600"
        (click)="createAnonymousStaticArrayNode()"
        [disabled]="disableAddProperty()"
      >
        Anonymous Static Array
      </button>
    </div>
  </mat-menu>
</div>

<ng-template #duplicateNameError>
  <div class="kf-ntofication-content" kfNotification kfNotificationStyle="Error">
    <kf-icon name="ic_warnschild_24"></kf-icon>
    <span class="kf-notification-text">Duplicate property name</span>
  </div>
</ng-template>
