import { Injectable } from '@angular/core';

import { TreeNodeUtil } from '@app/util/tree-node.util';
import { ExternalMappingData } from '@type/external/external-mapping.type';
import { DefaultData } from '@type/internal/default.data';
import { InternalMappingData, MappingSourceData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { ExternalNodeType, InternalNodeType } from '@type/shared/enum-mapping.type';

@Injectable({
  providedIn: 'root',
})
export class ImportMappingService {
  public convertFromJson(json: string): TreeNode<InternalMappingData> {
    const externalMappingDataRoot = this.deserializeJson(json);
    const treeRoot = this.convertTree(externalMappingDataRoot);
    return treeRoot;
  }

  private deserializeJson(object: Object): ExternalMappingData {
    const json = JSON.stringify(object);
    const exportDatas = <ExternalMappingData>JSON.parse(json);
    return exportDatas;
  }

  private convertTree(exportMapping: ExternalMappingData): TreeNode<InternalMappingData> {
    const rootTreeNode = this.convert(exportMapping);
    if (!rootTreeNode.data) {
      rootTreeNode.data = new InternalMappingData({ type: ExternalNodeType.OBJECT });
    }
    rootTreeNode.name = DefaultData.ROOT_NAME;
    rootTreeNode.kfNodeInitiallySelected = true;
    rootTreeNode.data.type = InternalNodeType.OBJECT;
    rootTreeNode.metaData = exportMapping.metaData;
    return rootTreeNode;
  }

  private convert(exportData: ExternalMappingData): TreeNode<InternalMappingData> {
    if (exportData.type === ExternalNodeType.STATIC_ARRAY) {
      const mappingSourceLength = exportData.fields!.length;
      const treeNode = this.convertStaticArray(exportData, mappingSourceLength);
      treeNode.name = exportData.name!;
      treeNode.data.selectedMappingSourceIndex = 0;
      treeNode.data.mappingSourceLength = mappingSourceLength;
      return treeNode;
    } else {
      return this.convertNonStatic(exportData);
    }
  }

  private convertNonStatic(exportData: ExternalMappingData): TreeNode<InternalMappingData> {
    const node = this.convertField(exportData);
    if (exportData.fields) {
      const treeChildrenNodes = exportData.fields.map(childDataNode => this.convert(childDataNode));
      node.addChildren(treeChildrenNodes);
    }
    return node;
  }

  private convertField(exportData: ExternalMappingData, mappingSourceLength?: number): TreeNode<InternalMappingData> {
    const nodeData = new InternalMappingData(exportData);
    if (exportData.mappers) {
      nodeData.mappingSources = [{ mappers: exportData.mappers }];
    }

    if (mappingSourceLength) {
      nodeData.selectedMappingSourceIndex = 0;
      nodeData.mappingSourceLength = mappingSourceLength;
      nodeData.mappingSources = TreeNodeUtil.fillMappingSourceData(mappingSourceLength, nodeData.mappingSources ?? []);
    }

    return new TreeNode<InternalMappingData>(exportData.name!, nodeData);
  }

  private convertStaticArray(
    staticArrayMapping: ExternalMappingData,
    mappingSourceLength: number
  ): TreeNode<InternalMappingData> {
    if (staticArrayMapping.fields && staticArrayMapping.fields.length > 0) {
      return this.convertAnonymousObject(staticArrayMapping.fields, mappingSourceLength);
    }
    const nodeData = new InternalMappingData({ type: ExternalNodeType.STATIC_ARRAY });
    return new TreeNode<InternalMappingData>(staticArrayMapping.name ?? '', nodeData);
  }

  private convertAnonymousObject(
    anonymousObjects: ExternalMappingData[],
    mappingSourceLength: number,
    name?: string
  ): TreeNode<InternalMappingData> {
    const expected: ExternalMappingData = anonymousObjects[0];
    const invalidStructure = anonymousObjects.find(actual => !this.hasSameProperties(actual, expected));
    if (invalidStructure) {
      console.error('invalid strucure for: ' + invalidStructure);
      throw new Error('Invalid structure');
    }

    if (expected.fields) {
      return this.convertAnonymousObjectNodes(anonymousObjects, expected, mappingSourceLength, name);
    } else {
      return this.convertAnonymousObjectField(anonymousObjects, expected, mappingSourceLength);
    }
  }

  private convertAnonymousObjectNodes(
    anonymousObjects: ExternalMappingData[],
    expected: ExternalMappingData,
    mappingSourceLength: number,
    name?: string
  ) {
    const childrenNodes: TreeNode<InternalMappingData>[] = this.createAnonymousObjectStructure(
      anonymousObjects,
      expected.fields!,
      mappingSourceLength
    );
    const parentNode = this.convertField(expected, mappingSourceLength);
    parentNode.addChildren(childrenNodes);
    if (name) {
      parentNode.name = name;
    }
    return parentNode;
  }

  private createAnonymousObjectStructure(
    anonymousObjects: ExternalMappingData[],
    expectedFields: ExternalMappingData[],
    mappingSourceLength: number
  ): TreeNode<InternalMappingData>[] {
    const childrenNodes: TreeNode<InternalMappingData>[] = [];
    for (let index in expectedFields) {
      const anonymousChildObjects = anonymousObjects.map(ao => {
        return ao.fields![index];
      });

      if (anonymousChildObjects[0].type === ExternalNodeType.STATIC_ARRAY) {
        if (anonymousChildObjects[0].fields) {
          childrenNodes[index] = this.convertAnonymousObject(
            anonymousChildObjects[0].fields,
            anonymousChildObjects[0].fields.length,
            anonymousChildObjects[0].name
          );
        }
      } else {
        childrenNodes[index] = this.convertAnonymousObject(anonymousChildObjects, mappingSourceLength);
      }
    }
    return childrenNodes;
  }

  private convertAnonymousObjectField(
    anonymousObjects: ExternalMappingData[],
    expected: ExternalMappingData,
    mappingSourceLength: number
  ): TreeNode<InternalMappingData> {
    const mappingDatas: MappingSourceData[] = anonymousObjects.map(ao => {
      return {
        mappers: ao.mappers!,
      };
    });

    const fieldNode = this.convertField(expected, mappingSourceLength);
    fieldNode.data.mappingSources = mappingDatas;
    return fieldNode;
  }

  private hasSameProperties(actual: ExternalMappingData, expected: ExternalMappingData): boolean {
    return (
      actual.type == expected.type &&
      actual.name == expected.name &&
      actual.source == expected.source &&
      actual.format == expected.format &&
      actual.inputFormat == expected.inputFormat &&
      actual.outputFormat == expected.outputFormat &&
      actual.fields?.length == expected.fields?.length
    );
  }
}
