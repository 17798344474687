import { Injectable } from '@angular/core';
import { ExternalMappingData, MetaData } from '@type/external/external-mapping.type';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import {
  DataFormatType,
  ExternalNodeType,
  InternalNodeType,
  MapperValueType,
  toExternalType,
} from '@type/shared/enum-mapping.type';
import { Mapper, MapperCondition, MapperValue } from '@type/shared/mapper.type';

@Injectable({
  providedIn: 'root',
})
export class ExportMappingService {
  constructor() {}

  public convertFromTree(root: TreeNode<InternalMappingData>, metaData: MetaData): ExternalMappingData {
    const fields: ExternalMappingData[] = root.children.map(node => this._convertNode(node));
    return {
      type: ExternalNodeType.ANONYMOUS_OBJECT,
      metaData: metaData,
      fields: fields,
    };
  }

  private _convertNode(internalData: TreeNode<InternalMappingData>, mappingIndex?: number): ExternalMappingData {
    if (internalData.data.type === InternalNodeType.STATIC_ARRAY) {
      return this._convertStaticNode(internalData);
    } else if (internalData.data.type === InternalNodeType.ANONYMOUS_STATIC_ARRAY) {
      return this._convertAnonymousStaticNode(internalData);
    } else {
      const externalData: ExternalMappingData = {
        type: toExternalType(internalData.data.type),
      };
      switch (internalData.data.type) {
        case InternalNodeType.ANONYMOUS_ARRAY:
        case InternalNodeType.ARRAY:
          externalData.source = internalData.data.source;
          externalData.name = internalData.name;
          externalData.fields = internalData.children.map(node => this._convertNode(node, mappingIndex));
          break;
        case InternalNodeType.OBJECT:
          externalData.name = internalData.name;
          externalData.fields = internalData.children.map(node => this._convertNode(node, mappingIndex));
          break;
        case InternalNodeType.FIELD:
          externalData.name = internalData.name;
          externalData.format = internalData.data.format;
          if (externalData.format === DataFormatType.DATE_TIME) {
            externalData.inputFormat = internalData.data.inputFormat;
            externalData.outputFormat = internalData.data.outputFormat;
          }
          externalData.mappers = this._convertMappers(internalData.data, mappingIndex);
          break;
      }
      return externalData;
    }
  }

  private _convertMappers(internalData: InternalMappingData, mappingIndex: number = 0): Mapper[] {
    if (
      internalData.mappingSources === undefined ||
      internalData.mappingSources.length === 0 ||
      internalData.mappingSources[0].mappers === undefined
    ) {
      return [];
    }
    return internalData.mappingSources![mappingIndex].mappers.map(mapper => this._convertMapper(mapper));
  }

  private _convertStaticNode(internalData: TreeNode<InternalMappingData>): ExternalMappingData {
    const anonymousObjects: ExternalMappingData[] = [];
    anonymousObjects.push(this._convertAnonymousObject(internalData.children, 0));
    for (let i = 1; i < internalData.data.mappingSourceLength!; i++) {
      anonymousObjects.push(this._convertAnonymousObject(internalData.children, i));
    }

    const staticArray: ExternalMappingData = {
      type: ExternalNodeType.STATIC_ARRAY,
      name: internalData.name,
      fields: anonymousObjects,
    };

    return staticArray;
  }

  private _convertAnonymousStaticNode(internalData: TreeNode<InternalMappingData>): ExternalMappingData {
    const anonymousObjects: ExternalMappingData[] = [];
    anonymousObjects.push(this._convertNode(internalData.children[0], 0));
    for (let i = 1; i < internalData.data.mappingSourceLength!; i++) {
      anonymousObjects.push(this._convertNode(internalData.children[0], i));
    }
    const staticArray: ExternalMappingData = {
      type: ExternalNodeType.ANONYMOUS_STATIC_ARRAY,
      name: internalData.name,
      fields: anonymousObjects,
    };

    return staticArray;
  }

  private _convertAnonymousObject(
    internalDatas: TreeNode<InternalMappingData>[],
    mappingIndex: number
  ): ExternalMappingData {
    const fields = internalDatas.map(node => this._convertNode(node, mappingIndex));
    const anonymousObject: ExternalMappingData = {
      type: ExternalNodeType.ANONYMOUS_OBJECT,
      fields: fields,
    };
    return anonymousObject;
  }

  private _convertMapper(mapper: Mapper): Mapper {
    return {
      value: this._convertValue(mapper.value),
      conditions: mapper.conditions.map(condition => this._convertCondition(condition)),
    };
  }

  private _convertValue(value: MapperValue): MapperValue {
    const convertedValue: MapperValue = {
      type: value.type,
    };
    switch (value.type) {
      case MapperValueType.CONST:
        convertedValue.value = value.value;
        break;
      case MapperValueType.SELECTED_PRODUCT_PROPERTY:
        convertedValue.property = value.property;
        convertedValue.paymentInterval = value.paymentInterval;
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
        convertedValue.contentId = value.contentId;
        convertedValue.property = value.property;
        convertedValue.paymentInterval = value.paymentInterval;
        break;
      case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
      case MapperValueType.GENERAL_DATA_FIELD_VALUE:
        convertedValue.dataFieldContentId = value.dataFieldContentId;
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_DATA_FIELD_VALUE:
        convertedValue.contentId = value.contentId;
        convertedValue.dataFieldContentId = value.dataFieldContentId;
        break;
      case MapperValueType.NULL:
        //nothing to map
        break;
    }
    return convertedValue;
  }

  private _convertCondition(condition: MapperCondition): MapperCondition {
    return {
      keyValue: this._convertValue(condition.keyValue),
      operator: condition.operator,
      matchingValue: this._convertValue(condition.matchingValue),
    };
  }
}
