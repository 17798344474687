<div class="tree-container">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="kf-side-navigation">
    <!--leaf node-->
    <mat-nested-tree-node
      class="kf-side-navigation-tree-node-container"
      matTreeNodeToggle
      kfSideNavigation
      tabindex="0"
      *matTreeNodeDef="let node"
      (nodeSelectionChanged)="handleNodeSelection($event)"
      [kfSideNavigationTreeControl]="treeControl"
      [kfSideNavigationNode]="node"
      [ngClass]="{ 'background-highlight': hasInvalidData }"
    >
      <div class="kf-side-navigation-tree-node kf-side-navigation-single-line-text">
        <div class="kf-side-navigation-tree-node-expand-icon">
          <div class="bi bi-dot" style="font-size: 1.5rem"></div>
        </div>
        <div class="kf-side-navigation-tree-node-text-container"></div>
        <div class="kf-side-navigation-tree-node-text-description node-name" [attr.invalid]="hasInvalidData(node)">
          {{ node.name }}
        </div>
      </div>
    </mat-nested-tree-node>

    <!-- expandable nodes -->
    <mat-nested-tree-node
      class="kf-side-navigation-nested-tree-node"
      kfSideNavigation
      tabindex="0"
      *matTreeNodeDef="let node; when: isNotField"
      (nodeSelectionChanged)="handleNodeSelection($event)"
      [kfSideNavigationTreeControl]="treeControl"
      [kfSideNavigationNode]="node"
      [ngClass]="{ 'background-highlight': hasInvalidData }"
    >
      <div class="kf-side-navigation-tree-node kf-side-navigation-single-line-text" (click)="onSelectNode(node)">
        <div class="kf-side-navigation-tree-node-expand-icon">
          <kf-icon
            name="{{ treeControl.isExpanded(node) ? 'ic_chevron-unten_16' : 'ic_chevron-rechts_16' }}"
            matTreeNodeToggle
          >
          </kf-icon>
        </div>
        <div class="kf-side-navigation-tree-node-text-container"></div>
        <div class="kf-side-navigation-tree-node-text-description node-name" [attr.invalid]="hasInvalidData(node)">
          {{ node.name }}
        </div>
      </div>

      <!-- padding value depends on the mat-icon-button width.  -->
      <div
        [ngClass]="{ 'kf-side-navigation-tree-node-invisible': !treeControl.isExpanded(node) }"
        role="group"
        class="kf-side-navigation-tree-group"
      >
        <ng-container matTreeNodeOutlet></ng-container>
      </div> </mat-nested-tree-node
  ></mat-tree>
</div>
