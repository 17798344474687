import { Component, Input } from '@angular/core';
import { TreeNodeUtil } from '@app/util/tree-node.util';
import { InternalMappingData, MappingSourceData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { DataFormatType, InternalNodeType } from '@type/shared/enum-mapping.type';
import { Mapper } from '@type/shared/mapper.type';
import { MapperViewType } from './mapper-view.enum';

@Component({
  selector: 'app-field-mapper',
  templateUrl: './field-mapper.component.html',
  styleUrls: ['./field-mapper.component.scss'],
})
export class FieldMapperComponent {
  @Input() public treeNode?: TreeNode<InternalMappingData>;
  @Input() public mapperContext?: MapperViewType;

  constructor() {}

  public getSelectedMappingNode(): Mapper[] {
    return this.nodeData!.mappingSources![this.nodeData!.selectedMappingSourceIndex ?? 0].mappers;
  }

  public getMappingNode(index: number): Mapper[] {
    return this.nodeData!.mappingSources![index].mappers;
  }

  public getMapperTabLabel(index: number): string {
    return `Index ${index}`;
  }

  public getFormats(): DataFormatType[] {
    return Object.values(DataFormatType);
  }

  public getSelectableIndexes(): number[] {
    const maxSelectableIndex = this.nodeData?.mappingSources?.length ?? 0;
    return [...Array(maxSelectableIndex)].map((_, i) => i++);
  }

  public addMapperTab(): void {
    const staticArrayParent = TreeNodeUtil.getStaticArrayParent(this.treeNode!);
    if (staticArrayParent) {
      staticArrayParent.data.mappingSourceLength!++;
      this._walkDown(staticArrayParent, staticArrayParent.id, node =>
        node.data.mappingSources?.push(new MappingSourceData())
      );
    }
  }

  public removeMapperTab(index: number): void {
    const staticArrayParent = TreeNodeUtil.getStaticArrayParent(this.treeNode!);
    if (staticArrayParent) {
      staticArrayParent.data.mappingSourceLength!--;
      this._walkDown(staticArrayParent, staticArrayParent.id, node => node.data.mappingSources?.splice(index, 1));
    }
  }

  public isStaticArrayMapper(): boolean {
    return this.mapperContext === MapperViewType.STATIC_ARRAY_MAPPER;
  }

  public isDateTimeMapper(): boolean {
    return this.nodeData?.format === DataFormatType.DATE_TIME;
  }

  public get nodeData(): InternalMappingData | undefined {
    return this.treeNode?.data;
  }

  private _walkDown(
    currentNode: TreeNode<InternalMappingData>,
    staticParentId: string,
    action: (treeNode: TreeNode<InternalMappingData>) => void
  ) {
    if (!this._isOtherStaticArray(currentNode, staticParentId)) {
      action(currentNode);
      currentNode.children.forEach(childNode => this._walkDown(childNode, staticParentId, action));
    }
  }

  private _isOtherStaticArray(treeNode: TreeNode<InternalMappingData>, staticParentId: string): boolean {
    return (
      treeNode.id !== staticParentId &&
      (treeNode.data.type === InternalNodeType.STATIC_ARRAY ||
        treeNode.data.type === InternalNodeType.ANONYMOUS_STATIC_ARRAY)
    );
  }
}
