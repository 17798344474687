import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractContentData } from '@type/internal/internal-form.type';
import { BehaviorSubject, Observable, Subject, debounceTime, map, pipe, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchValueService<T extends AbstractContentData> implements OnDestroy {
  public fieldControl: UntypedFormControl;
  public selected?: T;
  public overlayOpen = new BehaviorSubject<boolean>(false);
  public filteredOptions?: T[];
  public emptyResult: boolean = false;
  public filteredWithNoResults: boolean = false;
  public showAutocompleteOverlay: boolean = false;
  public showContentId: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private _options: T[];
  private _triggerEvent: EventEmitter<boolean>;
  private _destroy: Subject<boolean> = new Subject();

  constructor() {
    this.fieldControl = new UntypedFormControl({
      disabled: false,
    });

    this.fieldControl.valueChanges
      .pipe(
        takeUntil(this._destroy),
        debounceTime(300),
        pipe(),
        map(value => this._strictFilter(value))
      )
      .subscribe(options => (this.filteredOptions = options));
    this._options = [];
    this._triggerEvent = new EventEmitter();
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public setInitialSelection(contentId: string | undefined): void {
    if (contentId) {
      this.fieldControl.setValue(contentId);
      this.selected = this._options.find(option => option.contentId === contentId);
      this.fieldControl.setValue(this.selected);
      console.log(this.selected);
    }
  }

  public setOverlayOpen(): void {
    this.overlayOpen.next(true);
    this._triggerEvent.emit(true);
  }

  public setOverlayClosed(): void {
    this.overlayOpen.next(false);
  }

  public toggleOverlay(shouldOpen: boolean, $event?: MouseEvent): void {
    if (shouldOpen) {
      this.setOverlayOpen();
    } else {
      this.setOverlayClosed();
      this._triggerEvent.emit(false);
      if ($event) {
        $event.stopPropagation();
      }
    }
  }

  public getOptionLabel(option: T): string {
    return this.showContentId.getValue() ? option.contentId : option.name;
  }

  public getOptionLabelFunction(): (option: T) => string {
    return (option: T) => this.getOptionLabel(option);
  }

  public setSelection(selected: T) {
    this.selected = selected;
  }

  public setInputFocus(): void {
    this.overlayOpen.next(true);
  }

  public toggleContentIdDisplay(): void {
    this.showContentId.next(!this.showContentId.getValue());
    if (this.selected) {
      this.fieldControl.setValue(this.selected);
    }
  }

  public disableToggleContentIdDisplay(): boolean {
    return this.options.length == 0;
  }

  public get options(): T[] {
    return this._options;
  }

  public set options(options: T[]) {
    this._showNameAsDefault(options);
    this._options = options;
    if (this._options.length > 0) {
      this.showAutocompleteOverlay = true;
      this.filteredOptions = options;
    }
  }

  private _showNameAsDefault(options: T[]): void {
    if (this.options.length === 0 && options.length > 0 && this.showContentId) {
      this.showContentId.next(false);
    }
  }

  private _strictFilter(value: T | string): T[] {
    const inputSearch = typeof value === 'string';
    if (inputSearch && this.options.length > 0) {
      const valueLabel = inputSearch ? value : this.getOptionLabel(value);
      const filterValue = valueLabel.toLowerCase();
      const filteredOptions = this.options.filter(option =>
        this.getOptionLabel(option).toLowerCase().includes(filterValue)
      );
      this.filteredWithNoResults = filteredOptions.length == 0;
      this.selected = undefined;
      return filteredOptions;
    } else if (this.options) {
      this.filteredWithNoResults = false;
      return this.options;
    }
    this.filteredWithNoResults = false;
    return [];
  }
}

function action(callback: (value: any) => void) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(subscriber => {
      return source.subscribe({
        next(value) {
          console.log(`field value changed to: ${value}`);
          subscriber.next(value);
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });
    });
  };
}
