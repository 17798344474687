export enum DataFieldTypeDTO {
  TEXT = 'TEXT',
  LONG_TEXT = 'LONG_TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  RADIO_GROUP = 'RADIO_GROUP',
  CHECKBOX = 'CHECKBOX',
  FILES_IMAGES = 'FILES_IMAGES',
  HIDDEN = 'HIDDEN',
  PRICE = 'PRICE',
}

export enum ProductTypeDTO {
  MAIN = 'MAIN',
  VARIANT = 'VARIANT',
  ADDITIONAL = 'ADDITIONAL',
}

export interface InstanceDTO {
  id: string;
  name: string;
  status: string;
  contentStream: string;
  contentVersion: string;
  live: boolean;
}

export interface DataFieldGroupDTO extends AbstractConfiguratorDTO {
  dataFields: DataFieldDTO[];
  productRefs: any[];
}

export interface DataFieldDTO extends AbstractConfiguratorDTO {
  type: DataFieldTypeDTO;
}

export interface CompositionDTO extends AbstractConfiguratorDTO {
  productRefs: ProductRefDTO[];
}

export interface ProductDTO extends AbstractConfiguratorDTO {}

interface AbstractConfiguratorDTO {
  id: string;
  contentId: string;
  displayName: string;
  active: boolean;
}

export interface ProductRefDTO {
  productId: string;
  type: ProductTypeDTO;
}
