import { UntypedFormControl, Validators } from '@angular/forms';
import { ExternalNodeType, InternalNodeType } from '../shared/enum-mapping.type';
import { InternalMappingData } from './internal-mapping.type';
import { TreeNode } from './tree-node.type';

export class DefaultData {
  public static readonly ROOT_NAME: string = 'Request Body';

  public static readonly ANONYMOUS_ARRAY_ELEMENT: string = 'AnonymousArrayElement';

  public static getEmptyDataTreeNode(): TreeNode<InternalMappingData> {
    const nodeData = new InternalMappingData({ type: ExternalNodeType.ANONYMOUS_OBJECT });
    const root = new TreeNode<InternalMappingData>(DefaultData.ROOT_NAME, nodeData);
    root.data.type = InternalNodeType.OBJECT;
    root.kfNodeInitiallySelected = true;
    return root;
  }
}

export class DefaultFormControl {
  public static readonly ALPHA_NUMERIC = new UntypedFormControl(
    {
      value: 'Text',
    },
    [Validators.required, Validators.pattern('[A-Za-z0-9]+')]
  );
}
