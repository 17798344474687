import {
  DataFieldDTO,
  DataFieldGroupDTO,
  DataFieldTypeDTO,
  InstanceDTO,
  ProductDTO,
} from '@type/external/banking-guide.type';
import {
  DataFieldType,
  DefaultType,
  ExternalNodeType,
  InternalNodeType,
  ProductType,
} from '../shared/enum-mapping.type';
import { ProductTypeDTO } from './../external/banking-guide.type';

export class InstanceData {
  public readonly id: string;
  public readonly name: string;
  public readonly status: string;
  public readonly contentStream: string;
  public readonly contentVersion: string;
  public readonly live: boolean;

  constructor(instance: InstanceDTO) {
    this.id = instance.id;
    this.name = instance.name;
    this.status = instance.status;
    this.contentStream = instance.contentStream;
    this.contentVersion = instance.contentVersion;
    this.live = instance.live;
  }
}

export class AbstractContentData {
  constructor(
    protected _id?: string,
    protected _contentId?: string,
    protected _displayName?: string,
    protected _parentDisplayName?: string
  ) {}

  public get id(): string {
    return this._id!;
  }

  public get contentId(): string {
    return this._contentId!;
  }

  public get name(): string {
    return `[${this._parentDisplayName!}] ${this._displayName!}`;
  }
}

export class DataFieldGroupData extends AbstractContentData {
  constructor(dataFieldGroup?: DataFieldGroupDTO) {
    super(dataFieldGroup?.id, dataFieldGroup?.contentId, dataFieldGroup?.displayName);
  }

  public override get name(): string {
    return `${this._displayName!}`;
  }
}

export class DataFieldData extends AbstractContentData {
  public readonly type?: DataFieldType;

  constructor(dataField?: DataFieldDTO, dataFieldGroup?: DataFieldGroupDTO) {
    super(dataField?.id, dataField?.contentId, dataField?.displayName, dataFieldGroup?.displayName);
    this.type = toDataFieldType(dataField?.type);
  }
}

export class ProductData extends AbstractContentData {
  public type?: ProductType;

  constructor(product: ProductDTO, type: ProductTypeDTO) {
    super(product.id, product.contentId, product.displayName);
    this.type = toProductType(type);
  }

  public override get name(): string {
    return `${this._displayName!}`;
  }
}

export function toNodeDataType(exportDataType?: ExternalNodeType): InternalNodeType {
  switch (exportDataType) {
    case ExternalNodeType.OBJECT:
      return InternalNodeType.OBJECT;
    case ExternalNodeType.ARRAY:
      return InternalNodeType.ARRAY;
    case ExternalNodeType.STATIC_ARRAY:
    case ExternalNodeType.ANONYMOUS_OBJECT:
      return InternalNodeType.STATIC_ARRAY;
    case ExternalNodeType.FIELD:
      return InternalNodeType.FIELD;
    case ExternalNodeType.ANONYMOUS_ARRAY:
      return InternalNodeType.ANONYMOUS_ARRAY;
    case ExternalNodeType.ANONYMOUS_STATIC_ARRAY:
      return InternalNodeType.ANONYMOUS_STATIC_ARRAY;
    default:
      return DefaultType.NODE_DATA;
  }
}

function toDataFieldType(dto?: DataFieldTypeDTO): DataFieldType {
  if (dto === undefined) {
    return DefaultType.DATA_FIELD;
  }
  switch (dto) {
    case DataFieldTypeDTO.TEXT:
      return DataFieldType.TEXT;
    case DataFieldTypeDTO.LONG_TEXT:
      return DataFieldType.LONG_TEXT;
    case DataFieldTypeDTO.NUMBER:
      return DataFieldType.NUMBER;
    case DataFieldTypeDTO.DATE:
      return DataFieldType.DATE;
    case DataFieldTypeDTO.DROPDOWN:
      return DataFieldType.DROPDOWN;
    case DataFieldTypeDTO.RADIO_GROUP:
      return DataFieldType.RADIO_GROUP;
    case DataFieldTypeDTO.CHECKBOX:
      return DataFieldType.CHECKBOX;
    case DataFieldTypeDTO.RADIO_GROUP:
      return DataFieldType.RADIO_GROUP;
    case DataFieldTypeDTO.FILES_IMAGES:
      return DataFieldType.FILES_IMAGES;
    case DataFieldTypeDTO.HIDDEN:
      return DataFieldType.HIDDEN;
    case DataFieldTypeDTO.PRICE:
      return DataFieldType.PRICE;
  }
}

function toProductType(dto?: ProductTypeDTO): ProductType {
  if (dto === undefined) {
    return DefaultType.PRODUCT;
  }
  switch (dto) {
    case ProductTypeDTO.MAIN:
      return ProductType.MAIN;
    case ProductTypeDTO.VARIANT:
      return ProductType.VARIANT;
    case ProductTypeDTO.ADDITIONAL:
      return ProductType.ADDITIONAL;
  }
}
