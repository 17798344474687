import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { InstanceContextService } from '@service/instance-context.service';
import { AbstractContentData, DataFieldData, ProductData } from '@type/internal/internal-form.type';
import { MapperValueType, PaymentIntervalType, ProductPropertyType } from '@type/shared/enum-mapping.type';
import { MapperValue, hasPaymentInterval } from '@type/shared/mapper.type';
import { Subject, takeUntil } from 'rxjs';
import { SearchValueService } from '../search-value/search-value.service';

@Component({
  selector: 'app-single-value',
  templateUrl: './single-value.component.html',
  styleUrls: ['./single-value.component.scss'],
})
export class SingleValueComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public value?: MapperValue;
  public readonly valueType: typeof MapperValueType;
  public readonly productPropertyType: typeof ProductPropertyType;
  public readonly paymentIntervalType: typeof PaymentIntervalType;

  public readonly generalDataFieldSearchValue: SearchValueService<DataFieldData> = new SearchValueService();
  public readonly productDataFieldSearchValue: SearchValueService<DataFieldData> = new SearchValueService();
  public readonly additionalProductsSearchValue: SearchValueService<ProductData> = new SearchValueService();
  private _destroy: Subject<boolean> = new Subject();

  constructor(private _instanceContextService: InstanceContextService) {
    this.valueType = MapperValueType;
    this.productPropertyType = ProductPropertyType;
    this.paymentIntervalType = PaymentIntervalType;
  }

  ngOnInit(): void {
    this._instanceContextService.generalDataFieldsSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(generalDF => (this.generalDataFieldSearchValue.options = generalDF));
    this._instanceContextService.productDataFieldSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(productDF => (this.productDataFieldSearchValue.options = productDF));
    this._instanceContextService.additionalProductsSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(additionalProducts => (this.additionalProductsSearchValue.options = additionalProducts));

    if (this.value) {
      this._setSearchFieldValue(this.value);

      this.generalDataFieldSearchValue.fieldControl.valueChanges
        .pipe(takeUntil(this._destroy))
        .subscribe(value => this._setValue(SearchValueType.GENERAL_DFV, value));
      this.productDataFieldSearchValue.fieldControl.valueChanges
        .pipe(takeUntil(this._destroy))
        .subscribe(value => this._setValue(SearchValueType.PRODUCT_DFV, value));
      this.additionalProductsSearchValue.fieldControl.valueChanges
        .pipe(takeUntil(this._destroy))
        .subscribe(value => this._setValue(SearchValueType.ADDITIONAL_PRODUCT_DFV, value));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newValue = changes['value'].currentValue;
    this._setSearchFieldValue(newValue);
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public showPaymentInterval(): boolean {
    return hasPaymentInterval(this.value?.property);
  }

  public changeValueType() {
    switch (this.value!.type) {
      case MapperValueType.GENERAL_DATA_FIELD_VALUE:
        this.value!.contentId = undefined;
        this.value!.dataFieldContentId = this.generalDataFieldSearchValue.selected?.contentId;
        this.value!.paymentInterval = undefined;
        this.value!.property = undefined;
        this.value!.value = undefined;
        break;
      case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
        this.value!.contentId = undefined;
        this.value!.dataFieldContentId = this.productDataFieldSearchValue.selected?.contentId;
        this.value!.paymentInterval = undefined;
        this.value!.property = undefined;
        this.value!.value = undefined;
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_DATA_FIELD_VALUE:
        this.value!.contentId = this.additionalProductsSearchValue.selected?.contentId;
        this.value!.dataFieldContentId = this.productDataFieldSearchValue.selected?.contentId;
        this.value!.paymentInterval = undefined;
        this.value!.property = undefined;
        this.value!.value = undefined;
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
        this.value!.contentId = this.additionalProductsSearchValue.selected?.contentId;
        this.value!.dataFieldContentId = undefined;
        this.value!.value = undefined;
        break;
      case MapperValueType.SELECTED_PRODUCT_PROPERTY:
        this.value!.contentId = undefined;
        this.value!.dataFieldContentId = undefined;
        this.value!.value = undefined;
        break;
      case MapperValueType.CONST:
        this.value!.contentId = undefined;
        this.value!.dataFieldContentId = undefined;
        this.value!.paymentInterval = undefined;
        this.value!.property = undefined;
        break;
      case MapperValueType.NULL:
        this.value!.contentId = undefined;
        this.value!.dataFieldContentId = undefined;
        this.value!.paymentInterval = undefined;
        this.value!.property = undefined;
        this.value!.value = undefined;
        break;
    }
  }

  public get valueTypeChoices(): MapperValueType[] {
    return Object.values(MapperValueType);
  }

  public get productPropertyChoices(): ProductPropertyType[] {
    return Object.values(ProductPropertyType);
  }

  public get paymentIntervalTypeChoices(): PaymentIntervalType[] {
    return Object.values(PaymentIntervalType);
  }

  private _setValue(searchValueType: SearchValueType, value: string | AbstractContentData): void {
    const valueContentId = typeof value === 'string' ? value : value.contentId;
    switch (searchValueType) {
      case SearchValueType.GENERAL_DFV:
        this.value!.dataFieldContentId = valueContentId;
        break;
      case SearchValueType.PRODUCT_DFV:
        this.value!.dataFieldContentId = valueContentId;
        break;
      case SearchValueType.ADDITIONAL_PRODUCT_DFV:
        this.value!.contentId = valueContentId;
        break;
    }
  }

  private _setSearchFieldValue(value: MapperValue) {
    switch (value.type) {
      case MapperValueType.GENERAL_DATA_FIELD_VALUE:
        this.generalDataFieldSearchValue.setInitialSelection(value.dataFieldContentId);
        break;
      case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
        this.productDataFieldSearchValue.setInitialSelection(value.dataFieldContentId);
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
        this.additionalProductsSearchValue.setInitialSelection(value.contentId);
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_DATA_FIELD_VALUE:
        this.additionalProductsSearchValue.setInitialSelection(value.contentId);
        this.productDataFieldSearchValue.setInitialSelection(value.dataFieldContentId);
        break;
    }
  }
}

enum SearchValueType {
  GENERAL_DFV = 'GENERAL_DFV',
  PRODUCT_DFV = 'PRODUCT_DFV',
  ADDITIONAL_PRODUCT_DFV = 'ADDITIONAL_PRODUCT_DFV',
}
