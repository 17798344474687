import { Component, OnDestroy, OnInit } from '@angular/core';
import { InstanceContextService } from '@service/instance-context.service';
import { TreeContextService } from '@service/tree-context.service';
import { DefaultData } from '@type/internal/default.data';
import { AbstractContentData, DataFieldGroupData } from '@type/internal/internal-form.type';
import { IS_TYPE_STATIC_ARRAY, InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { InternalNodeType } from '@type/shared/enum-mapping.type';
import { Subject, takeUntil } from 'rxjs';
import { MapperViewType } from './field-mapper/mapper-view.enum';
import { SearchValueService } from './field-mapper/search-value/search-value.service';

@Component({
  selector: 'app-node-detail',
  templateUrl: './node-detail.component.html',
  styleUrls: ['./node-detail.component.scss'],
})
export class NodeDetailComponent implements OnInit, OnDestroy {
  public selectedNode?: TreeNode<InternalMappingData>;
  public mapperContext?: MapperViewType;
  public readonly dataFieldGrouSearchValue: SearchValueService<DataFieldGroupData>;
  private _destroy: Subject<boolean>;

  constructor(
    dataFieldGroupSearchValue: SearchValueService<DataFieldGroupData>,
    private _selectTreeNodeService: TreeContextService,
    private _instanceContextService: InstanceContextService
  ) {
    this.dataFieldGrouSearchValue = dataFieldGroupSearchValue;
    this._destroy = new Subject();
  }

  ngOnInit(): void {
    this._selectTreeNodeService.selectedTreeNodeSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(selected => this._changeSelectedNode(selected));

    this._instanceContextService.dataFieldGroupSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(dfgs => (this.dataFieldGrouSearchValue.options = dfgs));
    if (this.selectedNode) {
      this.dataFieldGrouSearchValue.setInitialSelection(this.selectedNode.data.source);

      this.dataFieldGrouSearchValue.fieldControl.valueChanges
        .pipe(takeUntil(this._destroy))
        .subscribe(value => this._setValue(value));
    }
  }

  private _setValue(value: string | AbstractContentData): void {
    const valueContentId = typeof value === 'string' ? value : value.contentId;
    this.selectedNode!.data.source = valueContentId;
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public isDisabledName(): boolean {
    return this.selectedNode?.name === DefaultData.ANONYMOUS_ARRAY_ELEMENT || this.selectedNode!.isRoot();
  }

  public isObjectView(selectedNode: TreeNode<InternalMappingData>): boolean {
    return selectedNode.isRoot() || selectedNode.data.type !== InternalNodeType.FIELD;
  }

  public isArrayType(selectedNode: TreeNode<InternalMappingData>): boolean {
    return (
      selectedNode.data.type === InternalNodeType.ARRAY || selectedNode.data.type === InternalNodeType.ANONYMOUS_ARRAY
    );
  }

  public get typeChoices(): InternalNodeType[] {
    return Object.values(InternalNodeType);
  }

  private _changeSelectedNode(selectedNode: TreeNode<InternalMappingData>) {
    this.selectedNode = selectedNode;
    this.mapperContext = this._calcMapperContext(selectedNode);
  }

  private _calcMapperContext(treeNode: TreeNode<InternalMappingData>): MapperViewType {
    const staticArrayContext: boolean = treeNode.walkUp(IS_TYPE_STATIC_ARRAY);
    if (staticArrayContext) {
      return MapperViewType.STATIC_ARRAY_MAPPER;
    }
    return MapperViewType.FLAT_MAPPER;
  }
}
