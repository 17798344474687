import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NavigationNode } from '@de.fiduciagad.kundenportal/kf-theme/side-navigation';
import { TreeContextService } from '@service/tree-context.service';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { InternalNodeType } from '@type/shared/enum-mapping.type';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-tree',
  templateUrl: './node-navigation.component.html',
  styleUrls: ['./node-navigation.component.scss'],
})
export class NodeNavigationComponent implements OnInit, OnDestroy {
  private _treeControl: NestedTreeControl<NavigationNode>;
  private _dataSource: MatTreeNestedDataSource<NavigationNode>;
  private _destroy: Subject<boolean>;

  constructor(private selectTreeNodeService: TreeContextService) {
    this._treeControl = new NestedTreeControl(node => node.kfNodeChildren);
    this._dataSource = new MatTreeNestedDataSource();
    this._destroy = new Subject();
  }

  ngOnInit() {
    this.selectTreeNodeService.rootTreeNodeSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(root => this.initData(root));
    this.selectTreeNodeService.selectedTreeNodeSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(node => this.renderAgain(node));
  }

  ngOnDestroy() {
    this._destroy.next(true);
  }

  public isNotField = (_: number, node: NavigationNode): boolean =>
    (node as TreeNode<InternalMappingData>).data.type !== InternalNodeType.FIELD;

  public hasInvalidData(node: NavigationNode): boolean {
    return (node as TreeNode<InternalMappingData>).invalidData;
  }

  public handleNodeSelection(node: any): void {
    this.selectTreeNodeService.setSelected(node);
  }

  public initData(root: NavigationNode) {
    this.dataSource.data = [root];
  }

  public onSelectNode(node: NavigationNode): void {
    const treeNode = node as TreeNode<InternalMappingData>;
    if (treeNode.isRoot()) {
      this.selectTreeNodeService.setSelected(treeNode);
    }
  }

  private renderAgain(node: NavigationNode) {
    // FIXME hacky
    const save = this.dataSource.data;
    this.dataSource.data = [];
    this.dataSource.data = save;
  }

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(value) {
    this._dataSource = value;
  }

  public get treeControl() {
    return this._treeControl;
  }
  public set treeControl(value) {
    this._treeControl = value;
  }
}
