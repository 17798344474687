import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceConstant } from '@app/constants.data';
import { AbstractAuthenticationService } from '@service/authentication.service';
import { FileService, ImportResult, ImportResultType } from '@service/file.service';
import { InstanceContextService } from '@service/instance-context.service';
import { InstanceData } from '@type/internal/internal-form.type';
import { Subject, takeUntil } from 'rxjs';
import { TreeContextService } from './../../../service/tree-context.service';

@Component({
  selector: 'app-node-header',
  templateUrl: './node-header.component.html',
  styleUrls: ['./node-header.component.scss'],
})
export class NodeHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('importSuccessView') public importSuccessView?: TemplateRef<Element>;
  @ViewChild('importErrorView') public importErrorView?: TemplateRef<Element>;

  public instances?: InstanceData[];
  public selectedInstance?: InstanceData;
  public importIgnoreContext: boolean;
  public importErrorMessage: string;

  private _dialogRef?: MatDialogRef<Element>;
  private _destroy: Subject<boolean>;

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _dialog: MatDialog,
    private readonly _instanceContextService: InstanceContextService,
    @Inject(ServiceConstant.authenticationService)
    private readonly _authenticationService: AbstractAuthenticationService,
    private _fileService: FileService,
    private readonly _treeContextService: TreeContextService
  ) {
    this._destroy = new Subject<boolean>();
    this.importIgnoreContext = false;
    this.importErrorMessage = 'Import Error';
  }

  ngOnInit(): void {
    this._instanceContextService.instancesSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(instances => this._setInstances(instances));
    this._instanceContextService
      .getSelectedInstanceObservable()
      .pipe(takeUntil(this._destroy))
      .subscribe(next => this._setSelectedInstance(next));
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public selectInstance(instance: InstanceData | undefined) {
    this._instanceContextService.setNextSelectedInstance(instance);
    this.selectedInstance = instance;
  }

  public canRefresh(): boolean {
    return this._instanceContextService.canRefresh();
  }

  public openDialog(template: TemplateRef<Element>): void {
    if (this._dialogRef) {
      this._dialogRef.close();
    }
    this.importIgnoreContext = false;
    this._dialogRef = this._dialog.open(template, dialogViewConfig);
  }

  public onImportCancel(): void {
    this._dialogRef!.close();
  }

  public async onImportConfirm(event: any): Promise<void> {
    this._dialogRef!.close();
    const importResult: ImportResult = await this._fileService.importFile(
      event.target.files[0],
      this.importIgnoreContext
    );
    console.log(importResult);

    switch (importResult.type) {
      case ImportResultType.SUCCESS:
        this._snackBar.openFromTemplate(this.importSuccessView!);
        break;
      case ImportResultType.ERROR_TENANT_ID:
        this.importErrorMessage = `Import file contains mapping for tenant ${importResult.metaData!.tenantId}`;
        this._snackBar.openFromTemplate(this.importErrorView!);
        break;
      case ImportResultType.ERROR_INSTANCE_ID:
        this.importErrorMessage = `Import file contains mapping for instance ${importResult.metaData!.instanceName}`;
        this._snackBar.openFromTemplate(this.importErrorView!);
        break;
      case ImportResultType.ERROR_INVALID_MAPPING:
        this.importErrorMessage = `Import file contains invalid mapping structure`;
        this._snackBar.openFromTemplate(this.importErrorView!);
        break;
    }
  }

  public onExport(): void {
    this._fileService.exportAsFile();
  }

  public onValidate(): void {
    this._treeContextService.validate();
  }

  public onRefresh(): void {
    this._instanceContextService.refreshInstances();
  }

  public onLogOut(): void {
    this._authenticationService.logout();
  }

  private _setInstances(instances: InstanceData[]): void {
    //retain selection
    if (this.instances) {
      let same: boolean = true;
      instances.forEach((value, index) => {
        if (index >= this.instances!.length) {
          this.instances?.push(value);
          same = false;
        } else if (!this._sameInstance(this.instances![index], value)) {
          this.instances![index] = value;
          same = false;
        }
      });
      if (!same && this.selectedInstance!) {
        if (!this.instances.find(instance => instance.id === this.selectedInstance!.id)) {
          this._instanceContextService.setNextSelectedInstance(undefined);
        }
      }
    } else {
      this.instances = instances;
    }
  }

  private _sameInstance(one: InstanceData, two: InstanceData): boolean {
    return one.id === two.id;
  }

  private _setSelectedInstance(instance: InstanceData | undefined) {
    this.selectedInstance = instance;
  }
}

enum UploadStatus {
  SUCCESS,
  ERROR,
  IN_PROGRESS,
}

export const dialogViewConfig = {
  panelClass: 'kf-popup-wrap',
  maxWidth: 580,
  autoFocus: false,
};
