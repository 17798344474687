<div *ngIf="value">
  <mat-form-field class="notranslate kf-form-field-compact mb-2">
    <mat-label>Variable type</mat-label>
    <mat-select kfSelectMdc [(ngModel)]="value.type" (selectionChange)="changeValueType()">
      <mat-option *ngFor="let type of valueTypeChoices" [value]="type">{{ type }}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container [ngSwitch]="value.type">
    <ng-container *ngSwitchCase="valueType.CONST">
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput [(ngModel)]="value.value" placeholder="Value" (keydown)="$event.stopPropagation()" />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.GENERAL_DATA_FIELD_VALUE">
      <app-search-value
        [searchValue]="generalDataFieldSearchValue"
        [labelName]="'General Data Field'"
      ></app-search-value>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.PRODUCT_DATA_FIELD_VALUE">
      <app-search-value
        [searchValue]="productDataFieldSearchValue"
        [labelName]="'Product Data Field'"
      ></app-search-value>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.ADDITIONAL_PRODUCT_DATA_FIELD_VALUE">
      <app-search-value
        [searchValue]="additionalProductsSearchValue"
        [labelName]="'Additional Product'"
      ></app-search-value>
      <app-search-value
        [searchValue]="productDataFieldSearchValue"
        [labelName]="'Product Data Field'"
      ></app-search-value>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.ADDITIONAL_PRODUCT_PROPERTY">
      <ng-container *ngTemplateOutlet="productPropertyFields"></ng-container>
      <app-search-value
        [searchValue]="additionalProductsSearchValue"
        [labelName]="'Additional Product'"
      ></app-search-value>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.SELECTED_PRODUCT_PROPERTY">
      <ng-container *ngTemplateOutlet="productPropertyFields"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="valueType.NULL"></ng-container>
  </ng-container>
</div>

<ng-template #productPropertyFields>
  <div *ngIf="value">
    <mat-form-field class="notranslate kf-form-field-compact mb-2">
      <mat-label>Product property</mat-label>
      <mat-select kfSelectMdc [(ngModel)]="value.property">
        <mat-option *ngFor="let type of productPropertyChoices" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="notranslate kf-form-field-compact mb-2" *ngIf="showPaymentInterval()">
      <mat-label>Payment interval</mat-label>
      <mat-select kfSelectMdc [(ngModel)]="value.paymentInterval">
        <mat-option *ngFor="let type of paymentIntervalTypeChoices" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
