<div class="node-detail-container" *ngIf="selectedNode">
  <kf-tile class="node-detail-top-container" [interactive]="false">
    <mat-form-field class="notranslate kf-form-field-compact">
      <mat-label>Property name</mat-label>
      <input
        matInput
        [(ngModel)]="selectedNode.name"
        [disabled]="isDisabledName()"
        placeholder="Name"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field class="kf-form-field-compact">
      <mat-label>Property type</mat-label>
      <mat-select kfSelectMdc [(ngModel)]="selectedNode.data.type" [disabled]="selectedNode.isRoot()">
        <mat-option *ngFor="let type of typeChoices" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="isArrayType(selectedNode)">
      <app-search-value [searchValue]="dataFieldGrouSearchValue" [labelName]="'Data Field Group'"></app-search-value>
    </div>
  </kf-tile>

  <div *ngIf="isObjectView(selectedNode); then objectView; else fieldMapperView"></div>

  <ng-template #fieldMapperView>
    <app-field-mapper [treeNode]="selectedNode" [mapperContext]="mapperContext"></app-field-mapper
  ></ng-template>

  <ng-template #objectView>
    <app-object-node [selectedTreeNode]="selectedNode"></app-object-node>
  </ng-template>
</div>
