import { format } from 'date-fns/format';
import { ExternalMappingData } from '../external/external-mapping.type';
import { DataFormatType, InternalNodeType, MapperValueType } from '../shared/enum-mapping.type';
import { Mapper, hasPaymentInterval } from '../shared/mapper.type';
import { toNodeDataType } from './internal-form.type';
import { TreeNode } from './tree-node.type';
import { Validatable } from './validatable.type';

export const IS_TYPE_STATIC_ARRAY: (treeNode: TreeNode<InternalMappingData>) => boolean = (
  treeNode: TreeNode<InternalMappingData>
) => {
  return (
    treeNode.data.type === InternalNodeType.STATIC_ARRAY ||
    treeNode.data.type === InternalNodeType.ANONYMOUS_STATIC_ARRAY
  );
};

export class InternalMappingData extends Validatable {
  public type: InternalNodeType;
  public source?: string;
  public mappingSources?: MappingSourceData[];
  public format?: DataFormatType;
  public selectedMappingSourceIndex?: number;
  public mappingSourceLength?: number;
  public inputFormat?: string;
  public outputFormat?: string;

  constructor(mappingData?: ExternalMappingData, mappingSources?: MappingSourceData[]) {
    super();
    this.type = toNodeDataType(mappingData?.type);
    switch (this.type) {
      case InternalNodeType.OBJECT:
      case InternalNodeType.ARRAY:
      case InternalNodeType.ANONYMOUS_ARRAY:
        this._mapObjectArrayData(mappingData);
        break;
      case InternalNodeType.ANONYMOUS_STATIC_ARRAY:
      case InternalNodeType.STATIC_ARRAY:
        this._mapStaticArrayData(mappingData);
        break;
      case InternalNodeType.FIELD:
        this._mapFieldData(mappingData, mappingSources);
        break;
    }
  }

  public override isInvalid(): boolean {
    switch (this.type) {
      case InternalNodeType.STATIC_ARRAY:
      case InternalNodeType.OBJECT:
      case InternalNodeType.ANONYMOUS_ARRAY:
      case InternalNodeType.ANONYMOUS_STATIC_ARRAY:
        return false;
      case InternalNodeType.ARRAY:
        return this.source ? false : true;
      case InternalNodeType.FIELD:
        if (this.mappingSources === undefined || this.format === undefined) {
          return true;
        } else if (this.format === DataFormatType.DATE_TIME && this._isInvalidDateTime()) {
          return true;
        }
        return this.mappingSources.map(source => this._isInvalidMappingSource(source)).reduce((l, r) => l || r, false);
    }
  }

  private _mapObjectArrayData(mappingData?: ExternalMappingData) {
    this.source = mappingData?.source;
  }

  private _mapStaticArrayData(mappingData?: ExternalMappingData) {
    this.selectedMappingSourceIndex = 0;
    this.mappingSourceLength = mappingData?.fields?.length ?? 0;
  }

  private _mapFieldData(mappingData?: ExternalMappingData, mappingSources?: MappingSourceData[]) {
    this.mappingSources = mappingSources ?? [new MappingSourceData()];
    this.format = mappingData?.format;
    if (mappingData?.inputFormat) {
      this.inputFormat = mappingData?.inputFormat;
    }
    if (mappingData?.outputFormat) {
      this.outputFormat = mappingData?.outputFormat;
    }
  }

  private _isInvalidDateTime(): boolean {
    return (
      this.inputFormat === undefined ||
      this.outputFormat === undefined ||
      this._isInvalidDateTimePattern(this.inputFormat) ||
      this._isInvalidDateTimePattern(this.outputFormat)
    );
  }

  private _isInvalidMappingSource(mappingSource: MappingSourceData): boolean {
    if (mappingSource.mappers.length === 0) {
      return true;
    }
    return mappingSource.mappers.map(mapper => this._isInvalidMapper(mapper)).reduce((l, r) => l || r, false);
  }

  private _isInvalidMapper(mapper: Mapper): boolean {
    switch (mapper.value.type) {
      case MapperValueType.CONST:
        return this._invalidMapping(mapper.value.value);
      case MapperValueType.SELECTED_PRODUCT_PROPERTY:
        return this._isInvalidProductPropertyMapper(mapper);
      case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
        return this._isInvalidProductPropertyMapper(mapper) || this._invalidMapping(mapper.value.contentId);
      case MapperValueType.ADDITIONAL_PRODUCT_DATA_FIELD_VALUE:
        return this._invalidMapping(mapper.value.dataFieldContentId, mapper.value.contentId);
      case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
      case MapperValueType.GENERAL_DATA_FIELD_VALUE:
        return this._invalidMapping(mapper.value.dataFieldContentId);
      case MapperValueType.NULL:
        return false;
    }
  }

  private _invalidMapping(...mappings: (string | undefined)[]): boolean {
    return mappings.some(mapping => (mapping ? false : true));
  }

  private _isInvalidProductPropertyMapper(mapper: Mapper): boolean {
    if (this._invalidMapping(mapper.value.property)) {
      return true;
    }

    if (hasPaymentInterval(mapper.value.property)) {
      return this._invalidMapping(mapper.value.paymentInterval);
    }

    return false;
  }

  private _isInvalidDateTimePattern(pattern: string): boolean {
    try {
      const testDate = new Date(2000, 0, 1);
      format(testDate, pattern);
      return false;
    } catch (error: any) {
      console.error(`Invalid pattern: ${error.message}`);
      return true;
    }
  }
}

export class MappingSourceData {
  mappers: Mapper[] = [];
}
